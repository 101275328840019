import React from "react"
import { Toaster } from "react-hot-toast"
import "./src/styles/global.scss"

export const wrapPageElement = ({ element, props }) => (
  <main>
    {element}
    <Toaster />
  </main>
)
